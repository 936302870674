<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center" align-content="center" class="fill-height">
      <template v-if="loading === 1">
        <v-col cols="12" sm="auto">
          <v-progress-circular size="120"
                               color="primary"
                               class="mb-10"
                               indeterminate>
          </v-progress-circular>
        </v-col>
        <v-col cols="12">
          <div class="display-3 text-center mb-8">
            {{ $t('paymentLoading') }}
          </div>
          <div class="text-center display-1">
            {{ $t('dontCloseTheBrowser') }}
          </div>
        </v-col>
      </template>
      <template v-else-if="loading === 2">
        <v-col cols="12" sm="auto">
          <div class="mx-auto mb-10">
            <v-icon :color="success ? 'success' : 'error'" size="120">
              {{ success ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline' }}
            </v-icon>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="display-3 text-center mb-8">
            {{ success ? $t('paymentSuccess') : $t('paymentFailed') }}
          </div>
          <div class="text-center">
            Tranzakció adatai:
          </div>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-btn color="primary"
                   class="mr-2"
                   large
                   @click="goToRequestDeatails">
              Tovább az igény részleteihez
            </v-btn>
            <v-btn color="primary"
                   class="ml-2"
                   large
                   @click="$router.push({ name: 'iranyitopult'})">
              Tovább a profilra
            </v-btn>
          </v-row>
        </v-col>
      </template>
      <template v-else>
        <v-row></v-row>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { getRQI } from 'src/utils/jwtHelper';

export default {
  name: 'SuccessPage',
  components: {
    //
  },
  props: {
    //
  },
  async beforeMount() {
    // console.log('token: ', this.$route.query.token);
    // console.log('PayerID: ', this.$route.query.PayerID);
    if (this.$route.query.token) {
      this.loading = 1;
      this.loading = await this.checkPaymentStatus();
      this.rqi = getRQI();
    } else {
      this.$router.push({ name: 'home' });
    }
  },
  data() {
    return {
      loading: 0,
      success: false,
      rqi: null,
    };
  },
  methods: {
    async checkPaymentStatus() {
      const response = await this.$store.dispatch('checkPaymentStatus',
        {
          token: this.$route.query.token,
          payerID: this.$route.query.PayerID
        }
      );
      this.success = response.status === 200 || response.status === 201;
      return 2;
    },
    goToRequestDeatails() {
      this.$router.push({ name: 'igeny-reszletei', params: { crmRequestId: this.rqi} });
    },
  },
};
</script>

<style scoped>

</style>
