import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height"},[_c(VRow,{staticClass:"fill-height",attrs:{"justify":"center","align":"center","align-content":"center"}},[(_vm.loading === 1)?[_c(VCol,{attrs:{"cols":"12","sm":"auto"}},[_c(VProgressCircular,{staticClass:"mb-10",attrs:{"size":"120","color":"primary","indeterminate":""}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"display-3 text-center mb-8"},[_vm._v(" "+_vm._s(_vm.$t('paymentLoading'))+" ")]),_c('div',{staticClass:"text-center display-1"},[_vm._v(" "+_vm._s(_vm.$t('dontCloseTheBrowser'))+" ")])])]:(_vm.loading === 2)?[_c(VCol,{attrs:{"cols":"12","sm":"auto"}},[_c('div',{staticClass:"mx-auto mb-10"},[_c(VIcon,{attrs:{"color":_vm.success ? 'success' : 'error',"size":"120"}},[_vm._v(" "+_vm._s(_vm.success ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline')+" ")])],1)]),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"display-3 text-center mb-8"},[_vm._v(" "+_vm._s(_vm.success ? _vm.$t('paymentSuccess') : _vm.$t('paymentFailed'))+" ")]),_c('div',{staticClass:"text-center"},[_vm._v(" Tranzakció adatai: ")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","large":""},on:{"click":_vm.goToRequestDeatails}},[_vm._v(" Tovább az igény részleteihez ")]),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.$router.push({ name: 'iranyitopult'})}}},[_vm._v(" Tovább a profilra ")])],1)],1)]:[_c(VRow)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }